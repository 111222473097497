// Firebase setup and services
import { initializeApp } from "firebase/app";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  initializeFirestore,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAHdFr6fvhhoAwHqeo7q12AVZhnf9optAA",
  authDomain: "dataleaders-15a29.firebaseapp.com",
  databaseURL: "https://dataleaders-15a29.firebaseio.com",
  projectId: "dataleaders-15a29",
  storageBucket: "dataleaders-15a29.appspot.com",
  messagingSenderId: "559169877701",
  appId: "1:559169877701:web:0dfef123d9527c4074a2ac",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = initializeFirestore(firebaseApp, {
  experimentalForceLongPolling: true,
});

const DB_COLLECTION_COMPANIES = "companies";

export const getQuestions = async () => {
  const questionQuery = query(collection(db, "Membership_questions"));
  const snapshot = await getDocs(questionQuery);
  return snapshot.docs.map((doc) => {
    return { ...doc.data(), sectionKey: doc.id };
  }, []);
};

export const getAnswers = async (clientSlug) => {
  // Check if form previously submitted
  const checkDoc = await getDoc(doc(db, "Membership_answers", clientSlug));

  if (!checkDoc.exists()) {
    return {};
  } else {
    return checkDoc.data();
  }
};

export const fetchListOfCompanies = async () => {
  const q = query(collection(db, DB_COLLECTION_COMPANIES));
  const querySnapshot = await getDocs(q);

  const rawCompanyData = querySnapshot.docs.map((doc) => {
    const data = doc.data();
    const companyName = data.company_name;
    const specialisations = data.specialisations_domains;
    if (data.approved) {
      return { companyName, specialisations };
    } else {
      return null;
    }
  });

  return rawCompanyData.filter((rcd) => rcd !== null);
};

export const saveProgress = async (clientSlug, data) => {
  const checkDoc = await getDoc(doc(db, "Membership_answers", clientSlug));

  if (!checkDoc.exists()) {
    await setDoc(doc(db, "Membership_answers", clientSlug), data);
  } else {
    await updateDoc(doc(db, "Membership_answers", clientSlug), data);
  }
};

export const submitAnswers = async (clientSlug, data) => {
  await setDoc(doc(db, "Membership_answers", clientSlug), data);
};
