import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { saveProgress, submitAnswers } from "../../utils/firebase";
import { storageKey } from "../../utils";

export const uploadProgress = createAsyncThunk(
  "upload/uploadProgress",
  async ({ client, submission }) => {
    await saveProgress(client, { currentProgress: submission });
  }
);

export const uploadSubmission = createAsyncThunk(
  "upload/uploadSubmission",
  async ({ client, metaData, answers, history }) => {
    const data = {
      currentProgress: {},
      submissions: [...history, { meta: metaData, answers }],
    };

    await submitAnswers(client, data);
  }
);

const uploadSlice = createSlice({
  name: "upload",
  initialState: {
    isUploading: false,
    uploadError: false,
  },
  extraReducers: (builder) => {
    // Save Progress
    builder
      .addCase(uploadProgress.pending, (state) => {
        state.isUploading = true;
        state.uploadError = false;
      })
      .addCase(uploadProgress.fulfilled, (state) => {
        state.isUploading = false;
      })
      .addCase(uploadProgress.rejected, (state) => {
        state.isUploading = false;
        state.uploadError = true;
      });
    // Upload submission
    builder
      .addCase(uploadSubmission.pending, (state) => {
        state.isUploading = true;
        state.uploadError = false;
      })
      .addCase(uploadSubmission.fulfilled, (state) => {
        state.isUploading = false;
        localStorage.removeItem(storageKey);
      })
      .addCase(uploadSubmission.rejected, (state) => {
        state.isUploading = false;
        state.uploadError = true;
      });
  },
});

export const selectUploadStatus = (state) => state.upload;

export const { setClientSlug } = uploadSlice.actions;

export default uploadSlice.reducer;
