import React from "react";
import { Box, Container } from "@mui/material";
import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";
import { Outlet } from "react-router";

export default function AppLayout() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <PageHeader />
      <Box
        component="main"
        sx={{
          backgroundColor: "white",
          flexGrow: 1,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          px: { xxs: 2, xs: 4 },
        }}
      >
        <Outlet />
      </Box>
      <PageFooter />
    </Container>
  );
}
