import React from "react";
import { Box, Link, Typography } from "@mui/material";

export default function PageFooter() {
  return (
    <Box
      component="footer"
      sx={{
        flexShrink: "0",
        p: "15px",
        borderTop: "2px solid",
        borderColor: "secondary.main",
        display: "flex",
      }}
    >
      <Typography
        variant="subtitle1"
        fontSize="13px"
        noWrap
        sx={{ flex: "1 1 0", mr: "1rem" }}
      >
        &copy; {new Date().getFullYear()} Data Leaders™ | All Rights Reserved
      </Typography>
      <Link
        component="a"
        href="https://dataleaders.net/privacy-policy/"
        target="_blank"
        rel="noopener"
        variant="subtitle1"
        fontSize="13px"
        underline="none"
      >
        Privacy Policy
      </Link>
    </Box>
  );
}
