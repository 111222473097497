import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchListOfCompanies,
  getAnswers,
  getQuestions,
} from "../../utils/firebase";

export const loadQuestions = createAsyncThunk(
  "load/loadQuestions",
  async () => {
    const allQuestions = await getQuestions();

    allQuestions.sort((a, b) => {
      return a.sectionId - b.sectionId;
    });

    return allQuestions;
  }
);

export const loadCompanies = createAsyncThunk(
  "load/loadCompanies",
  async () => {
    const allCompanies = await fetchListOfCompanies();
    return allCompanies;
  }
);

export const loadHistory = createAsyncThunk(
  "load/loadHistory",
  async (client) => {
    const allSubmissions = await getAnswers(client);
    return allSubmissions;
  }
);

const loadSlice = createSlice({
  name: "load",
  initialState: {
    questions: {
      qLoading: false,
      qError: false,
      qData: [],
    },
    companies: {
      cLoading: false,
      cError: false,
      cData: [],
    },
    history: {
      checkForHistory: true,
      hLoading: false,
      hError: false,
      hData: {},
    },
  },
  extraReducers: (builder) => {
    // Load Questions
    builder
      .addCase(loadQuestions.pending, (state) => {
        state.questions.qLoading = true;
        state.questions.qError = false;
      })
      .addCase(loadQuestions.fulfilled, (state, action) => {
        state.questions.qLoading = false;
        state.questions.qData = action.payload;
      })
      .addCase(loadQuestions.rejected, (state) => {
        state.questions.qLoading = false;
        state.questions.qError = true;
      });

    // Load Companies
    builder
      .addCase(loadCompanies.pending, (state) => {
        state.companies.cLoading = true;
        state.companies.cError = false;
      })
      .addCase(loadCompanies.fulfilled, (state, action) => {
        state.companies.cLoading = false;
        state.companies.cData = action.payload;
      })
      .addCase(loadCompanies.rejected, (state) => {
        state.companies.cLoading = false;
        state.companies.cError = true;
      });

    // Load History
    builder
      .addCase(loadHistory.pending, (state) => {
        state.history.hLoading = true;
        state.history.hError = false;
      })
      .addCase(loadHistory.fulfilled, (state, action) => {
        state.history.hLoading = false;
        state.history.hData = action.payload;
        state.history.checkForHistory = false;
      })
      .addCase(loadHistory.rejected, (state) => {
        state.history.hLoading = false;
        state.history.hError = true;
      });
  },
});

export const selectQuestions = (state) => state.load.questions;
export const selectCompanies = (state) => state.load.companies;
export const selectHistory = (state) => state.load.history;

export default loadSlice.reducer;
