import { configureStore } from "@reduxjs/toolkit";
import loadReducer from "./features/Load/loadSlice";
import submissionReducer from "./features/Submission/submissionSlice";
import uploadReducer from "./features/Upload/uploadSlice";

export default configureStore({
  reducer: {
    load: loadReducer,
    submission: submissionReducer,
    upload: uploadReducer,
  },
});
