import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

// Create a theme instance.
let theme = createTheme({
  palette: {
    primary: {
      main: "#29859b",
    },
    secondary: {
      main: "#030348",
    },
    error: {
      main: red.A400,
    },
    info: {
      main: "#485B74",
    },
    divider: "rgba(51,51,51,0.33)",
    background: {
      default: "#ececec",
    },
  },
  shape: {
    borderRadius: 6,
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 450,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
      styleOverrides: {
        root: {
          color: "unset",
          ":hover": {
            color: "#29859b",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          flex: "1 1 auto",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
