import { createSlice } from "@reduxjs/toolkit";
import slugify from "slugify";
import { storageKey } from "../../utils";

const submissionSlice = createSlice({
  name: "submission",
  initialState: {
    answers: {},
    metaData: {
      startedAt: "",
      completedBy: "",
      email: "",
      companyName: "",
      completedAt: "",
    },
    visited: [],
    focusAreas: {},
    focusAreaCount: 0,
    clientSlug: "",
    sectionErrors: {},
    sectionErrorCount: 0,
  },
  reducers: {
    getFromStorage: (state) => {
      const legacyAnswers = localStorage.getItem("answers");
      const legacyFocusAreas = localStorage.getItem("focusAreas");
      const storage = localStorage.getItem(storageKey);

      if (legacyAnswers || legacyFocusAreas) {
        localStorage.removeItem("answers");
        localStorage.removeItem("focusAreas");
        localStorage.removeItem("step");

        const legacyState = {
          ...state,
          answers: JSON.parse(legacyAnswers) || {},
          focusAreas: JSON.parse(legacyFocusAreas) || {},
          focusAreaCount: Object.values(state.focusAreas).filter(
            (fa) => fa !== ""
          ).length,
        };

        localStorage.setItem(storageKey, JSON.stringify(legacyState));

        return legacyState;
      } else if (storage) {
        return JSON.parse(storage);
      }
    },
    getFromDB: (state, action) => {
      const useLocal = state.visited.length >= action.payload.visited.length;
      const sameUser = action.payload.metaData.email === state.metaData.email;

      if (!useLocal && sameUser) {
        return action.payload;
      }
    },
    setVisited: (state, action) => {
      state.visited.push(action.payload);
      localStorage.setItem(storageKey, JSON.stringify(state));
    },
    setMetaData: (state, action) => {
      const { name, value } = action.payload;
      const hasStartTime = !!state.metaData.startedAt;

      if (!(name === "startedAt" && hasStartTime)) {
        state.metaData[name] = value;
      }

      if (name === "companyName") {
        state.clientSlug = slugify(value, { lower: true });
      }

      localStorage.setItem(storageKey, JSON.stringify(state));
    },
    setCompletedTime: (state, action) => {
      state.metaData.completedAt = action.payload;
      localStorage.setItem(storageKey, JSON.stringify(state));
    },
    setAnswer: (state, action) => {
      const { section, qid, value } = action.payload;

      if (!state.answers[section]) {
        state.answers[section] = {};
      }

      if (["5.2.1", "5.3.1", "5.4.1"].includes(qid)) {
        state.focusAreas[qid] = value;
        state.focusAreaCount = Object.values(state.focusAreas).filter(
          (fa) => fa !== ""
        ).length;
      }

      state.answers[section][qid] = value;
      localStorage.setItem(storageKey, JSON.stringify(state));
    },
    addSectionErrors: (state, action) => {
      const { qid, section } = action.payload;

      state.sectionErrors[qid] = section;
      state.sectionErrorCount = Object.values(state.sectionErrors).filter(
        (se) => se !== ""
      ).length;

      localStorage.setItem(storageKey, JSON.stringify(state));
    },
    removeSectionErrors: (state, action) => {
      const qid = action.payload;

      delete state.sectionErrors[qid];
      state.sectionErrorCount = Object.values(state.sectionErrors).filter(
        (se) => se !== ""
      ).length;

      localStorage.setItem(storageKey, JSON.stringify(state));
    },
  },
});

export const selectFocusAreas = (state) => state.submission.focusAreas;
export const selectMetaData = (state) => state.submission.metaData;
export const selectSectionErrors = (state) => state.submission.sectionErrors;
export const selectSubmission = (state) => state.submission;

export const {
  getFromStorage,
  getFromDB,
  setFormData,
  setVisited,
  setMetaData,
  setCompletedTime,
  setAnswer,
  addSectionErrors,
  removeSectionErrors,
} = submissionSlice.actions;

export default submissionSlice.reducer;
