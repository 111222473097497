import React, { createContext, Suspense, useEffect, useState } from "react";
import AppLayout from "./components/layout/AppLayout";
import { BallTriangle } from "react-loader-spinner";
import StartPage from "./pages/Start";
import { Navigate, Route, Routes } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getFromStorage,
  selectMetaData,
} from "./features/Submission/submissionSlice";

export const FormContext = createContext();

const Lazy404 = React.lazy(() => import("./pages/404"));
const LazyError = React.lazy(() => import("./pages/Error"));
const LazyMenu = React.lazy(() => import("./pages/Menu"));
const LazySection = React.lazy(() => import("./pages/Section"));
const LazyThankYou = React.lazy(() => import("./pages/ThankYou"));

function RequireStart({ started, children }) {
  if (!started) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default function App() {
  const dispatch = useDispatch();
  const { startedAt } = useSelector(selectMetaData);

  useEffect(() => {
    dispatch(getFromStorage());
  }, []);

  return (
    <Routes>
      <Route
        path="*"
        element={
          <Suspense fallback={null}>
            <Lazy404 />
          </Suspense>
        }
      />
      <Route
        path="error"
        element={
          <Suspense fallback={null}>
            <LazyError />
          </Suspense>
        }
      />
      <Route element={<AppLayout />}>
        <Route path="/" element={<StartPage />} />
        <Route
          path="membership"
          element={
            <RequireStart started={!!startedAt}>
              <Suspense
                fallback={
                  <BallTriangle
                    color="#5ba4b6"
                    height={80}
                    width={80}
                    wrapperStyle={{ margin: "auto" }}
                  />
                }
              >
                <LazyMenu />
              </Suspense>
            </RequireStart>
          }
        />
        <Route
          path="membership/:section"
          element={
            <RequireStart started={!!startedAt}>
              <Suspense
                fallback={
                  <BallTriangle
                    color="#5ba4b6"
                    height={80}
                    width={80}
                    wrapperStyle={{ margin: "auto" }}
                  />
                }
              >
                <LazySection />
              </Suspense>
            </RequireStart>
          }
        />
        <Route
          path="complete"
          element={
            <Suspense
              fallback={
                <BallTriangle
                  color="#5ba4b6"
                  height={80}
                  width={80}
                  wrapperStyle={{ margin: "auto" }}
                />
              }
            >
              <LazyThankYou />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}
