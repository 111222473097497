import React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMetaData,
  setAnswer,
  setMetaData,
} from "../features/Submission/submissionSlice";

export default function StartPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { completedBy, email, companyName } = useSelector(selectMetaData);

  function handleInputChange(event) {
    const { name, value } = event.target;
    dispatch(setMetaData({ name, value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    const startedAt = new Date().toISOString();

    dispatch(setAnswer({ section: "details", qid: "1.1", value: companyName }));
    dispatch(setMetaData({ name: "startedAt", value: startedAt }));
    navigate("/membership");
  }

  return (
    <Box
      component="section"
      sx={{
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        m: "50px auto",
      }}
    >
      <Box
        component="form"
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          textAlign: "justify",
          maxWidth: "500px",
        }}
        onSubmit={handleSubmit}
      >
        <Box>
          <Typography component="h3" sx={{ mb: "20px" }}>
            Please enter your name, work email and your company to start the
            membership form. The results will remain confidential and we will
            not share your email with anyone else. No password required.
          </Typography>

          <TextField
            name="completedBy"
            type="text"
            label="Completed By"
            fullWidth
            required
            margin="normal"
            value={completedBy}
            onChange={handleInputChange}
          />
          <TextField
            name="email"
            type="email"
            label="Email"
            fullWidth
            required
            margin="normal"
            value={email}
            onChange={handleInputChange}
          />
          <TextField
            name="companyName"
            type="text"
            label="Organisation"
            fullWidth
            required
            margin="normal"
            value={companyName}
            onChange={handleInputChange}
          />
        </Box>
        <Box
          sx={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Button type="submit" variant="contained" size="large" fullWidth>
            Start form
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
